import  React,{Suspense } from 'react';
import {Link} from 'react-router-dom';
const Footer = React.lazy (()=> import('../pages/Footer'));


function Footerpg() {
  return (
    <>
    <section class="Bottom">
          <div class="container">
            <div class="row">
              <div class="footlogo col-lg-4 mb-4">
                <h1 style={{textalign:"right"}}>Alkhair Group</h1>
              <img src="Images/Alkhair.png" alt="/" style={{width:"200px",}}></img>
              </div>
              <div class="col-lg-4">
              <h5 class="text-light mb-4">Find us here</h5>
              <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>27-KM Arif Wala Road Kamir(Sahiwal)</p>
                    <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+9240-4306589</p>
                    <p class="mb-2"><i class="fa fa-envelope me-3"></i>alkhairfoodspk@gmail.com</p>
              </div>
              <div class="col-lg-2">
              <h5 class="text-light mb-4">Quick links</h5>
              <div className='links'>
              <Link to="/" style={{color:"white"}}>Home</Link><br/>
              <Link to="About" style={{color:"white"}}>About</Link><br/>
              <Link to="Products" style={{color:"white"}}>Our Products</Link><br/>
              <Link to="Project" style={{color:"white"}}>Our Projects</Link><br/>
              <Link to="Location" style={{color:"white"}}>Location</Link><br/>
                    </div>
              </div>
              <div class="col-lg-2">
              <h5 class="text-white mb-4">Get in Touch</h5>
                <div class="footericon">
                <a class="btn btn-square btn-outline-secondary text-white rounded-circle me-2" href="https://www.facebook.com/alkhairfoodswl" style={{color:"white"}}><i class="fab fa-facebook-f"></i></a>
                <a class="btn btn-square btn-outline-secondary text-white rounded-circle me-2" href="https://wa.me/+923002800122" style={{color:"white"}}><i class="fa-brands fa-whatsapp fa-lg"></i></a>
                <a class="btn btn-square btn-outline-secondary text-white rounded-circle me-2" href="mailto:alkhairfoodspk@gmail.com?body=My custom mail body" style={{color:"white"}}><i class="fa-solid fa-envelope fa-lg"></i></a>
                </div>
              </div>

            </div>
          
          </div>
        </section>

    </>
  )
}
export default Footerpg;
