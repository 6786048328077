import  React,{Suspense } from 'react';
import Card from 'react-bootstrap/Card';
const Products = React.lazy (()=> import('../pages/Products'));
function Productpg(){
    return(
      <Suspense>
<>
<div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2500">
      <img src="Images/Our Products Banner 1.png" class="d-block w-100" alt="Banner"/>
    </div>
    <div class="carousel-item" data-bs-interval="2500">
      <img src="Images/Our Products Banner 2.png" class="d-block w-100" alt="Banner"/>
    </div>
    <div class="carousel-item" data-bs-interval="2500">
      <img src="Images/Our Products Banner 3.png" class="d-block w-100" alt="Banner"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<section class="container" style={{marginLeft:"50px",marginRight:"50px"}}>
<div class="row">
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/milk 2.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title"> Raw Milk</h5>
        <p class="card-text">Uncompromised purity, straight from nature's source – our raw milk embodies a commitment to pristine quality.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in bulk quantity</small>
      </div>
    </div>
  </div>
  </card>
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/cream 1.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Cream</h5>
        <p class="card-text">Indulge in the pure richness of our milk cream, a testament to unrivaled purity and creamy perfection.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/cheedar 2.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Chedder Cheese</h5>
        <p class="card-text"> Purity in every bite of our Cheddar cheese, crafted to perfection for an unparalleled taste experience.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/cheese 4.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Mozrella cheese</h5>
        <p class="card-text">Savor the pure delight of our Mozzarella cheese, crafted with unwavering dedication to purity and exquisite taste.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
</div>
<br></br>
<div class="row">
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/pizza 1.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Pizza Cheese</h5>
        <p class="card-text">Purity with our pizza cheese – a blend of perfection that elevates every slice to a delightful culinary experience.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/yogurt 1.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Yougert </h5>
        <p class="card-text">Purity meets perfection & creamy delight that captivates your taste buds with each spoonful.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
  <card class="Product" style={{width: "19rem", Height:"50rem"}}>
  <div class="col">
    <div class="card h-100">
      <img src="Images/ghee1.jpg" class="card-img-top" style={{height:"13rem"}} alt="..."/>
      <div class="card-body">
        <h5 class="card-title">Desi Ghee</h5>
        <p class="card-text">Aklhair Desi Ghee ensures unparalleled purity, a testament to the finest quality in every spoonful.</p>
      </div>
      <div class="card-footer">
        <small class="text-body-secondary">Available in Bulk Quantity</small>
      </div>
    </div>
  </div>
  </card>
</div>
</section>
</>
</Suspense>
    );
}
export default Productpg;
