import './App.css';
import Home from "./components/pages/Home";
import About from './components/pages/About';
import Products from './components/pages/Products';
import Project from './components/pages/Project';
import Location from './components/pages/Location';
import Navbar from './components/inc/navbar';
import Footer from './components/pages/Footer';
import { BrowserRouter as Router, Routes,Route} from "react-router-dom";
function App() {
  return (
   <Router>
      <div>
        <Navbar></Navbar>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="about" element={<About/>} />
            <Route path="Project" element={<Project/>} />
            <Route path="Location" element={<Location/>} />
            <Route path="Products" element={<Products/>} />
          </Routes>
          <Footer></Footer>
      </div>
   </Router>
  );
}
export default App;
