import React, { useState } from 'react';

function Location() {
  return (
    <div>
      <div class="google-map">
       <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d8667.945263579617!2d73.03653661591662!3d30.439625572152536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDI2JzIwLjgiTiA3M8KwMDInMjIuMyJF!5e0!3m2!1sen!2s!4v1705347948069!5m2!1sen!2s"
        width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" 
        referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  );
}

export default Location;