import  React,{Suspense } from 'react';
const Home = React.lazy (()=> import('../pages/Home'));

function Homepage() {
  return (
   <Suspense>
   <div>
      <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="2000">
      <img src="Images/Front Banner 1.png" class="d-block w-100" alt="Banner"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="Images/Front Banner 2.png" class="d-block w-100" alt="Banner"/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="Images/Front Banner 3.png" class="d-block w-100" alt="Banner"/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<br/><br/>
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h1 class="head display-5 mb-5"> Alkhair Foods & Dairy Products</h1>
                <div style={{marginLeft:"70px", marginRight:"70px",fontSize:"18px"}}>
            <span class="paragraph">
             Welcome to Alkhair Foods and Dairy Products, where quality and delight converge! We pride ourselves 
             on delivering premium foods and dairy that not only satisfy your taste buds but also nourish your 
             well-being. From farm-fresh dairy delights to essential culinary treasures, Alkhair is your go-to 
             for top-notch quality and taste. Our commitment to excellence ensures you enjoy the finest flavors
             and nutritional goodness. With the best ingredients and strict quality control, Alkhair guarantees 
             nothing but the best for your satisfaction. Experience pure, wholesome goodness with 
             Alkhair Foods and Dairy Products - your ultimate destination for quality and taste.
            </span>
            </div>
            </div>
            
   <section>
   <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-4">
                <div class="card col-md-6 col-lg-4 wow fadeIn " data-wow-delay="0.1s">
                    <div class=" card1 h-100 p-4 p-xl-4">
                        <div class="d-flex align-items-center justify-content-between mb-2">
                            <div class="btn-square rounded-circle" style={{width:"64px", height:"64px", background:"White"}}>
                            <i class="fa-solid fa-people-group fa-2xl" style={{color: "#cead36"}}></i>
                            </div>
                        </div>
                        <h3 class="text-light">About Us</h3>
                        <span class="text-white"> Welcome to Alkhair Foods Dairy! We take pride in delivering the purest dairy delights 
                        to your table. Specializing in raw milk, cream, and exquisite cheeses sourced from the finest 
                        quality milk, our dedicated team crafts a rich and delightful experience. With a commitment to 
                        the highest standards of quality and flavor, Alkhair Foods strives to be your trusted provider
                         of premium dairy selections.Experience the joy of wholesome dairy with us.</span>
                    </div>
                </div>
                <div class="card col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                    <div class="card1 h-100 p-4 p-xl-4">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="btn-square rounded-circle" style={{width: "64px", height: "64px", background: "white"}}>
                            <i class="fa-solid fa-handshake-simple fa-2xl" style={{color: "#cead36"}}></i>
                            </div>
                        </div>
                        <h3 class="text-light">Our Mission</h3>
                        <span class="text-white">At Alkhair Foods, our mission is to bring pure and wholesome dairy joy to your table. 
                        Specializing in raw milk, cream, and a variety of delightful cheeses, we are committed to sourcing
                         the finest quality milk for products that guarantee purity and freshness.
                          With meticulous craftsmanship and unwavering excellence, we aim to provide a rich and delightful experience, 
                          upholding the highest standards of quality and flavor.</span>
                    </div>
                </div>
                <div class="card col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                    <div class="card1 h-100  p-4 p-xl-4">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                            <div class="btn-square rounded-circle" style={{width: "64px", height: "64px", background: "white"}}>
                            <i class="fa-solid fa-people-group fa-2xl" style={{color: "#cead36"}}></i>
                            </div>
                        </div>
                        <h3 class="text-light">Our vision</h3>
                        <span class="text-white">At Alkhair Foods, we strive to blend culinary excellence with nutritional goodness.
                         Our commitment is to offer premium, authentic products that delight the senses and nourish the body.
                        We aim to elevate dining experiences, promoting a culture of health and happiness
                        through globally inspired flavors. Alkhair Foods is your preferred choice for a journey of taste and well-being,
                         bringing people together to savor life's richness through exceptional food.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section> 
    <section>
        
  <div class="container-xxl py-5">
        <div class="container">
               <h1 class="display-5 mb-5 text-center p-6">Team Members</h1>
            <div class="row g-5">
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="Images/founder.jpg" style={{height: "280px", width: "500px", objectfit: "cover"}} alt="founder"/>
                            <div class="team-social">
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Rana Nazir Ahmad</h5>
                            <small><span class="text-success">Founder</span></small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="Images/co-founder.jpg" style={{height: "280px", width: "500px"}} alt="cofounder"/>
                            <div class="team-social">
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Rana Rizwan Ahmad</h5>
                            <small><span class="text-success">CEO </span></small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="Images/Rana Farhan .jpg" style={{height: "280px", width: "500px", objectfit: "cover"}}alt="img"/>
                            <div class="team-social">
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-whatsapp"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Rana Farhan Ahmad </h5>
                            <small><span class="text-success">Executive Director</span></small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div class="team-item">
                        <div class="overflow-hidden position-relative">
                            <img class="img-fluid" src="Images/Ahson javed.jpg" style={{height: "280px", width: "500px", objectfit: "cover"}}alt=""/>
                            <div class="team-social">
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-twitter"></i></a>
                                <a class="btn btn-square btn-dark rounded-circle m-1" href="/"><i class="fab fa-instagram"></i></a>
                            </div>
                        </div>
                        <div class="text-center p-4">
                            <h5 class="mb-0">Ahson Javed</h5>
                            <small><span class="text-success">General Manager Operations</span></small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    <section>
    <div class="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div class="container feature px-lg-0">
            <div class="row g-0 mx-lg-0">
                <div class="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                    <div class="p-lg-5 ps-lg-0">
                        <h1 class="display-5 mb-3">Why Choose Us</h1>
                        <p class="mb-4 pb-2">
                        At Alkhair, we stand as a beacon of quality and reliability in both the food and milk collection domains. 
                        Choosing us is not just a decision; it's an assurance of excellence.
                        We take pride in our commitment to delivering unparalleled quality and taste to our customers.
                        Choose Alkhair Foods for an exceptional blend of taste, quality, and convenience.   
                        </p>
                        <div class="row g-4">
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square rounded-circle" style={{width: "60px", height: "60px", backgroundColor: "lightgray"}}>
                                    <i class="fa-solid fa-star fa-2xl" style={{color: "#317235"}}></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-success mb-2">Premium Quality Produce</p>
                                        <h5 class="mb-0">Fresh & Highest Quality</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square rounded-circle" style={{width: "60px", height: "60px", backgroundColor: "lightgray"}}>
                                    <i class="fa-solid fa-filter fa-2xl" style={{color: "#317235"}}></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-success mb-2">Transparency</p>
                                        <h5 class="mb-0">Sustainability</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                    <div class="btn-square rounded-circle" style={{width: "60px", height: "60px", backgroundColor: "lightgray"}}>
                                    <i class="fa-solid fa-shield fa-2xl" style={{color: "#317235"}}></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-success mb-2">Standards</p>
                                        <h5 class="mb-0">Hygiene and Safety</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex align-items-center">
                                <div class="btn-square rounded-circle" style={{width: "60px", height: "60px", backgroundColor: "lightgray"}}>
                                    <i class="fa-solid fa-truck-arrow-right fa-2xl" style={{color: "#317235"}}></i>
                                    </div>
                                    <div class="ms-4">
                                        <p class="text-success mb-2">Delivery Services</p>
                                        <h5 class="mb-0">Timely Delivery</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pe-lg-0" style={{minheight: "400px"}}>
                    <div class="position-relative h-100">
                        <img class="position-absolute img-fluid w-100 h-100" src="Images/Why choose us.png" style={{objectfit: "cover"}} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
      <br/><br/>
      </div>
</Suspense>

  );
}

export default Homepage;