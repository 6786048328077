import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';


function Navbar() {
  return (
    <div>
      <div class="container-fluid bg-success px-5">
        <div class="row gx-4 d-none d-lg-flex">
            <div class="col-lg-6 text-start">
                <div class="h-100 d-inline-flex align-items-center py-3 me-4">
                    <div class="btn-sm-square rounded-circle bg-light me-2">
                        <small class="fa fa-map-marker-alt fa-lg text-success"></small>
                    </div>
                    <small class="text-white">27-KM Arif Wala Road Kamir,Sahiwal</small>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-3">
                    <div class="btn-sm-square rounded-circle bg-light me-2">
                        <small class="fa fa-envelope-open fa-lg text-success"></small>
                    </div>
                    <small class="text-white">alkhairfoodspk@gmail.com</small>
                </div>
            </div>
            <div class="col-lg-6 text-end">
                <div class="h-100 d-inline-flex align-items-center py-3 me-4">
                    <div class="btn-sm-square rounded-circle bg-light me-2">
                        <small class="fa fa-phone fa-lg text-success"></small>
                    </div>
                    <small class="text-white">+9240-4306589</small>
                </div>
                <div class="h-100 d-inline-flex align-items-center py-3">
                    <div class="btn-sm-square rounded-circle bg-light me-2">
                        <small class="far fa-clock fa-lg text-success"></small>
                    </div>
                    <small class="text-white">Mon - Fri: 9AM - 10PM</small>
                </div>
            </div>
        </div>
    </div>

    <nav class="navbar navbar-expand-lg bg-white navbar-light  p-0 px-3 px-lg-5">
    <Link to="/"class="navbar-brand d-flex align-items-center">
        <img src="Images/Alkhair.png" alt="/"/>
           <b style={{fontsize: "3rem", color: "green"}}><i>Al Khair Foods</i></b>
        </Link>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-4 py-lg-0">
            <Link to="/" class="nav-item nav-link active"><b>Home</b></Link>
                <Link to="About" class="nav-item nav-link"><b>About</b></Link>
                <Link to="Project" class="nav-item nav-link"><b>Our Projects</b></Link>
                <Link to="Products" class="nav-item nav-link"><b>Our Products</b></Link>
                <Link to="Location" class="nav-item nav-link"><b>Contact Us</b></Link>
            </div>
            <div class="h-100 d-lg-inline-flex align-items-center d-none">
                <a class="btn btn-square rounded-circle bg-success text-light r me-2" href="https://www.facebook.com/alkhairfoodswl"><i class="fab fa-facebook-f fa-lg"></i></a>
                <a class="btn btn-square rounded-circle bg-success text-light r me-2" href="https://wa.me/+923002800122"><i class="fa-brands fa-whatsapp fa-lg"></i></a>
                <a class="btn btn-square rounded-circle bg-success text-light r me-2" href="/"><i class="fa-brands fa-youtube fa-lg"></i></a>
                <a class="btn btn-square rounded-circle bg-success text-light r me-0" href="/"><i class="fab fa-instagram fa-lg"></i></a>
            </div>
        </div>
    </nav>
   

    </div>
    
  );
}

export default Navbar;