import  React,{Suspense } from 'react';
const About = React.lazy (()=> import('../pages/About'));
function Aboutus(){
    return(
        <div>
            <section class="header-banner backgroundmilk">
        <div class="container">
          <div class="banner">
            <div class="row">
             <div class="col-lg-6"><span class="heading1"><b>A Promise to Purity</b></span><br/>
                  <span class="headingtext1">Alkhair Milk is more than just a dairy product;<br/> it's a symbol of purity and wellness.
                     Its commitment to quality and the benefits it offers to its consumers make
                      it an excellent choice for those seeking a healthier lifestyle.
                       Whether you're looking to fortify your bones, boost your immune system,
                        or simply enjoy the rich taste of pure milk, Alkhair Milk is the ideal choice
                         for all your dairy needs. Make Alkhair Milk a part of your daily routine and experience 
                         the goodness of purity and wellness with every sip.
                </span>
             </div>
             <div class="col-lg-6">
              <img src="Images/puremilk.avif" class="firstimg mx-auto rounded-circle" alt="alkhair"/></div>
            </div>
         </div>
        </div>
       </section>
       <br/><br/>

       <section class="body-banner backgroundcheese">
        <div class="container">
          <div class="banner">
            <div class="row">
              <div class="col-lg-4">
                <img src="Images/chedder cheese.webp" class="secondimg mx-auto rounded-circle" alt="alkhair"/></div>
                <div class="col-lg-8"><span class="heading1"><b>Alkhair Chedder Cheese</b></span><br/>
                        <i><b><h2>Taste in every bite</h2></b></i>
                    <span class="headingtext1">Alkhair Cheddar Cheese is a testament to our unwavering 
                        commitment to purity and taste. Crafted from the purest ingredients,
                         it offers a tantalizing combination of creamy richness and a distinct, 
                         savory tang that delights the palate, making it the perfect
                          choice for those who demand nothing but the best in their cheese.
                  </span>
               </div>
            </div>
         </div>
        </div>
      </section>
      <br/><br/>

      <section class="header-banner backgroundmozrela">
        <div class="container">
          <div class="banner">
            <div class="row">
             <div class="col-lg-6"><span class="heading1"><b>Alkhair Mozrella Cheese</b></span><br/>
                  <span class="headingtext1">Alkhair Mozzarella Cheese stands as a testament to our 
                    dedication to purity and flavor. Made from the purest ingredients,
                     it embodies the essence of creamy indulgence with 
                     its melt-in-your-mouth texture and a delicious, mild yet distinct taste,
                      making it the ultimate choice for enhancing the flavors of your favorite dishes.
                </span>
             </div>
             <div class="col-lg-6">
              <img src="Images/mozrella cheese.webp" class="firstimg mx-auto rounded-circle" alt="alkhair"/></div>
            </div>
         </div>
        </div>
       </section>
       <br/><br/>

       <section class="body-banner backgroundbutter">
        <div class="container">
          <div class="banner">
            <div class="row">
              <div class="col-lg-4"><img src="Images/butter.avif" class="secondimg mx-auto rounded-circle" alt="alkhair"/></div>
                <div class="col-lg-8"><span class="heading1"><b>Alkhair Butter</b></span><br/>
                        <i><b><h2>Taste in every bite</h2></b></i>
                    <span class="headingtext1">Alkhair Butter is a harmonious blend of purity and flavor,
                         meticulously crafted to bring a touch of exquisite indulgence to every meal.
                          Made from the purest, freshest ingredients, it boasts a rich, creamy taste 
                          that transforms ordinary dishes into extraordinary culinary experiences,
                           making it the crowning jewel of your kitchen.
                  </span>
               </div>
            </div>
         </div>
        </div>
      </section>
      <br/><br/>

      <section class="header-banner backgroundghee">
        <div class="container">
          <div class="banner">
            <div class="row">
             <div class="col-lg-6"><span class="heading1"><b>Alkhair Desi Ghee</b></span><br/>
                  <span class="headingtext1">Alkhair Mozzarella Cheese stands as a testament to our 
                    dedication to purity and flavor. Made from the purest ingredients,
                     it embodies the essence of creamy indulgence with 
                     its melt-in-your-mouth texture and a delicious, mild yet distinct taste,
                      making it the ultimate choice for enhancing the flavors of your favorite dishes.
                </span>
             </div>
             <div class="col-lg-6">
              <img src="Images/desi ghee.webp" class="firstimg mx-auto rounded-circle" alt="alkhair"/></div>
            </div>
         </div>
        </div>
       </section>
       <br/><br/><br/>
        </div>
    );
}
export default Aboutus;