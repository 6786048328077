import  React,{Suspense } from 'react';
const Project = React.lazy (()=> import('../pages/Project'));


function Projectpage() {
  return (
    <Suspense>
    <div>
      <div class="container-xxl py-5">
        <div class="container">
            <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
                <h1 class="display-5 mb-5">Our Projects</h1>
            </div>
            
            <div class="row g-4 portfolio-container">
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/Web banner.png" alt="alkhair foods"/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Foods</h4>
                            <h5 class="lh-base mb-0">Raw milk, Cheese Variety, Ghee, Cream</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkkhir Foods</p>
                            <h5 class="lh-base mb-3">Raw milk, Cheese Variety, Ghee, Cream</h5>
                            <div class="d-flex justify-content-center">
                                <span class="project-note"> Working </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/main maruee.jpg" alt=""/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Marquee</h4>
                            <h5 class="lh-base mb-0">Where unforgettable moments meet elegant spaces, creating the perfect setting for your special occasions</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkhair Marquee</p>
                            <h5 class="lh-base mb-3">Where unforgettable moments meet elegant spaces, creating the perfect setting for your special occasions</h5>
                            <div class="d-flex justify-content-center">
                               <span class="project-note"> Coming Soon </span> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/motors1.jpeg" alt=""/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Motors</h4>
                            <h5 class="lh-base mb-0">Driving excellence with a commitment to quality and luxury, redefining your automotive experience</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkhair Motors</p>
                            <h5 class="lh-base mb-3">Driving excellence with a commitment to quality and luxury, redefining your automotive experience</h5>
                            <div class="d-flex justify-content-center">
                               <span class="project-note"> Coming soon </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.1s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/fashion3.jpeg" alt="fashion"/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Fashions</h4>
                            <h5 class="lh-base mb-0">Elevate your style with timeless elegance and trendsetting designs that reflect the epitome of fashion sophistication</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkhair Fashions</p>
                            <h5 class="lh-base mb-3">Elevate your style with timeless elegance and trendsetting designs that reflect the epitome of fashion sophistication</h5>
                            <div class="d-flex justify-content-center">
                               <span class="project-note"> Coming Soon </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.3s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/home1.jpg" alt="home"/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Real Estates</h4>
                            <h5 class="lh-base mb-0">Transforming dreams into addresses, where every property embodies the essence of quality, integrity, and the perfect place to call home.</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkhair Real Estates</p>
                            <h5 class="lh-base mb-3">Transforming dreams into addresses, where every property embodies the essence of quality, integrity, and the perfect place to call home.</h5>
                            <div class="d-flex justify-content-center">
                               <span class="project-note"> Coming Soon </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.5s">
                    <div class="portfolio-inner">
                        <img class="setimg img-fluid w-100" src="Images/Guardian of heaven.png" alt="heaven"/>
                        <div class="text-center p-4">
                            <h4 class="text-success mb-2">Alkhair Foundation</h4>
                            <h5 class="lh-base mb-0">Empowering lives, fostering positive change, and building a brighter future</h5>
                        </div>
                        <div class="portfolio-text text-center bg-white p-4">
                            <p class="text-success mb-2">Alkhair Foundation</p>
                            <h5 class="lh-base mb-3">Empowering lives, fostering positive change, and building a brighter future</h5>
                            <div class="d-flex justify-content-center">
                                <span class="project-note"> Coming Soon </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </Suspense>
  );
}

export default Projectpage;